export default {
  props: {
    d: Object,
    themeColor: {
      type: String,
      default: "#fff"
    }
  },
  computed: {
    list: function list() {
      return this.d.list || [];
    },
    reverse: function reverse() {
      return this.d.reverse || false;
    }
  },
  data: function data() {
    return {
      current: 0 // center: require("@/assets/pack/c/5.png"),

    };
  }
};