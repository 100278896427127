export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        name: "线上购物",
        image: "",
        ls: [{
          t1: "高</br>效",
          t2: "快速高效",
          c: "一瓶起订：定制不再受数量限制，满足消费者的不同需求。</br>一秒鉴真：手机靠一靠，快速查询真伪。北斗定位、区块链，为产品保驾护航。"
        }, {
          t1: "创</br>意",
          t2: "AR购物功能",
          c: "将AR与产品包装结合，真实的包装和虚拟的信息实时地叠加，实现产品信息的可视化，让消费者更直观的了解并选择商品，达到创意营销的目的。"
        }, {
          t1: "个</br>性",
          t2: "个性定制",
          c: "外观定制：解决定制业务运营沟通成本高。</br>视频定制：一物一码（芯）定制视频表达内容。</br>音频定制：可加载品牌音频广告内容，开盒即可播放与互动体验。"
        }]
      }, {
        name: "区块链",
        image: ""
      }],
      icons: [{
        icon: require("@/assets/pack/h/2/icon1.png"),
        name: "条形码"
      }, {
        icon: require("@/assets/pack/h/2/icon2.png"),
        name: "二维码"
      }, {
        icon: require("@/assets/pack/h/2/icon3.png"),
        name: "RFID"
      }, {
        icon: require("@/assets/pack/h/2/icon4.png"),
        name: "NFC"
      }]
    };
  },
  computed: {
    ls: function ls() {
      return this.list[0].ls;
    }
  }
};