import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4946509e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "wow animate__fadeInDown"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h2", {
    class: "wow animate__fadeInDown",
    innerHTML: $props.d.t
  }, null, 8, _hoisted_2), _createElementVNode("h5", _hoisted_3, _toDisplayString($props.d.c), 1)]);
}