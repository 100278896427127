export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        t: "产品出入库",
        t1: "产品出入库",
        c: "利用RFID技术，使用配套的RFID设备，完成产品的快速出入库，批量计数，大大减少人工操作，并可自动记录出入库时间和数量",
        image: require("@/assets/pack/f/1.jpg")
      }, {
        t: "实时监控",
        t1: "实时监控",
        c: "物流各环节实现物流信息的可视化，包括物流资源信息、物流需求信息、物流过程、物流状态、物流控制和物流环境的可视化",
        image: require("@/assets/pack/f/2.png")
      }, {
        t: "市场营销",
        t1: "市场营销",
        c: "通过系统了解消费者的消费习惯和获取消费者信息，有利于做针对性营销活动",
        image: require("@/assets/pack/f/3.png")
      }]
    };
  },
  computed: {
    width: function width() {
      var len = this.list.length;
      var current = this.current;
      var translateX = -current * (100 / len) + "%";
      return {
        c: {
          width: len * 100 + "%",
          transform: "translateX(".concat(translateX, ")")
        },
        cItem: {
          width: 100 / len + "%"
        }
      };
    }
  }
};