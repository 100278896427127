export default {
  data: function data() {
    return {
      list: [{
        img: require("@/assets/pack/b/1.png"),
        t: "智能包装 1.0版本",
        t1: "智能包装 + webAPP（产品介绍网页)",
        tList: ["包装与商业网站链接", "后台管理系统", "NFC触碰展示", "基本数据收集", "社交网络连接", "电子商务", "广告"]
      }, {
        img: require("@/assets/pack/b/2.png"),
        t: "智能包装 2.0版本",
        t1: "智能包装 + webAPP + Dashboard",
        tList: ["包装与商业网站链接", "后台管理系统", "NFC触碰展示", "基本数据收集", "社交网络连接", "电子商务", "广告", "用户信息统计", "产品反馈数据统计", "用户反馈产品效果", "一拍一键即可保修", "保修数据实时反馈", "产品市场普及情况"]
      }, {
        img: require("@/assets/pack/b/3.png"),
        t: "智能包装 3.0版本",
        t1: "智能包装 + webAPP + Dashboard + RFID",
        tList: ["包装与商业网站链接", "后台管理系统", "NFC触碰展示", "基本数据收集", "社交网络连接", "电子商务", "广告", "用户信息统计", "产品反馈数据统计", "用户反馈产品效果", "一拍一键即可保修", "保修数据实时反馈", "产品市场普及情况", "RFID扫描能力", "产品的溯源及跟踪", "机器人客服", "零售商店产品受欢迎数据", "主流仓库管理系统的API对接", "AR", "VR线上购物", "区块链"]
      }],
      option: {
        defaultTop: 50
      },
      current: 0
    };
  }
};