export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/pack/g/icon1.png"),
        t: "目标市场营销",
        c: "集中所有资源于正确的消费者，量身定制推广活动。"
      }, {
        icon: require("@/assets/pack/g/icon2.png"),
        t: "防伪",
        c: "通过交叉认证服务器与RFID &NFC 标签芯片技术来辨别产品真伪，给予消费者更多的信心。"
      }, {
        icon: require("@/assets/pack/g/icon3.png"),
        t: "企业自动化",
        c: "把特定情况、推广和促销等活动变为自动化运营，更精确和省时。"
      }, {
        icon: require("@/assets/pack/g/icon4.png"),
        t: "大数据分析",
        c: "通过商业智能收集和分析产品、客户、业务和制造的数据以获得更准确的预测。"
      }, {
        icon: require("@/assets/pack/g/icon5.png"),
        t: "多种服务",
        c: "各样服务和技术都可应用于智能包装平台，没有限制。"
      }, {
        icon: require("@/assets/pack/g/icon6.png"),
        t: "直接与消费者沟通",
        c: "跳过中间渠道，直接与您的消费者沟通，并在智能包装平台上了解他们的需求和意见。"
      }]
    };
  }
};