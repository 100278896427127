import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7875cee6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src", "alt"];
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "list-icon"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "list-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "pack-c-container",
    style: _normalizeStyle({
      backgroundColor: $props.themeColor
    })
  }, [_createElementVNode("div", {
    class: _normalizeClass(["main", {
      reverse: $options.reverse
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["image wow", $options.reverse ? 'animate__fadeInRight' : 'animate__fadeInLeft'])
  }, [_createElementVNode("img", {
    src: $props.d.image,
    alt: $props.d.t
  }, null, 8, _hoisted_1)], 2), _createElementVNode("div", {
    style: _normalizeStyle([{
      "width": "0.8rem"
    }, {
      backgroundColor: $props.themeColor
    }])
  }, null, 4), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "content-t wow animate__fadeInDown",
    style: _normalizeStyle({
      backgroundColor: $props.themeColor
    })
  }, _toDisplayString($props.d.t), 5), _createElementVNode("div", {
    class: "content-c wow animate__fadeInDown",
    style: _normalizeStyle({
      backgroundColor: $props.themeColor
    })
  }, _toDisplayString($props.d.c), 5), _createElementVNode("div", {
    class: "list",
    style: _normalizeStyle({
      backgroundColor: $props.themeColor
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["list-item", {
        'is-c': item.c
      }]),
      onClick: function onClick($event) {
        return $data.current === index ? $data.current = null : $data.current = index;
      },
      key: index
    }, [item.c ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["list-jt", {
        current: $data.current === index
      }])
    }, null, 2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
      src: item.icon
    }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
      class: _normalizeClass(["list-t", {
        current: !item.c || index === $data.current
      }]),
      style: _normalizeStyle({
        backgroundColor: $props.themeColor
      })
    }, _toDisplayString(item.t), 7), item.c ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["list-c", {
        current: $data.current === index
      }])
    }, _toDisplayString(item.c), 3)) : _createCommentVNode("", true)])], 10, _hoisted_3);
  }), 128))], 4)])], 2)], 4);
}